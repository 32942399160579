/**
 * A class that captules all the direct interaction with the underlaying engine.
 * Responsible for loading, effects and lower level tasks.
 */

import {
    AssetInfoTypes,
    AssetProvider, AssetProviderMode,
    Configuration,
    Configurator,
    Engine,
    Grouping,
    IAsset,
    IAssetProviderConfig,
    MaterialGroup,
    VisibilityGroup
} from "@viscircle-org/threngine";
//import { ScreenshotRequest } from "threngine/dist/types/src/core/render/renderService";
import { Box3Helper, Vector3, Box3, Object3D, Color, AnimationMixer, LoopOnce } from "three";
import { IConfigurator } from "@viscircle-org/threngine/dist/types/src/core/configuration/configurator";
import { osName } from "react-device-detect";
import { Services } from "@viscircle-org/threngine/dist/types/src/engine";




export let modelSet = new Map();
export let materialSet = new Map();
export let materialGroup: any[] = [];
export let backgrounds: any[] = [];
export let visibilitySet: any[] = [];
export let renderSettings: any[] = [];
export let interiorSet: any[] = [];

export let groupingMap = new Map<string, Grouping[]>();
export let defaultMaterialMap = new Map<string, string>();
export let materialNameMap = new Map<string, IAsset>();
export let visibiliySetGeometryMap = new Map<string, string>();
export let textureNameMap = new Map<string, IAsset>();

export interface IRenderEngine {
    settings: any,
    configurator: IConfigurator,

    initEngine(mount: HTMLDivElement): Promise<any>,
}

export class RenderEngine implements IRenderEngine {
    private _sceneConfig: Configuration;
    private _config: IAssetProviderConfig;
    public _engine: Engine;
    private _assetProvider: AssetProvider;
    private _configurator: Configurator;

    get settings() { return this._engine.services.render.settings };
    get configurator(): Configurator { return this._configurator; }
    get scene() { return this._engine.services.render.scene }
    get services(): Services { return this._engine.services }

    public metaData: any= {};

    initEngine = (): Promise<any> => {
        this._config = {
            mode: AssetProviderMode.Local,
            //mode: AssetProviderMode.Dev,
            project_id: "61b1e2380982360016470139",
            server: "https://srv.3dcommerce.studio",
                local: {
                release: "/public/release/release.json",
                assets: "/public/release/assets"
            }//*/
            //local_folder:"/public/release"
        };
        return this._intialize();

    }

    private _intialize = (): Promise<any> => {

        const mount = document.getElementById("scene-canvas") as HTMLDivElement;

        if (window.parent && osName.toLowerCase().indexOf("os") >= 0) {
            console.log("in ios iframe")
            this._assetProvider = new AssetProvider(this._config);
        }
        else {
            this._assetProvider = new AssetProvider(this._config);
        }

        this._engine = new Engine(this._assetProvider);

        return this._engine.initialize(mount)
            .then(() => {
                this._sceneConfig = this._assetProvider.getAssets().find(a => a.infoType === "SCENE").info.data;
                this._engine.services.render.fadeOut(0, "#1d1b1b");
                this._initPostEffects();
                //return this._engine.services.config.initializeConfigurator(this._sceneConfig);
                this._configurator = new Configurator(this._sceneConfig, this._engine);
                return this._configurator.initialize().then((scene) => {
                    this._engine.services.render.setScene(scene);
                    this._engine.services.render.fadeIn(1000, "#1d1b1b");

                    // console.log("mat:", this._configurator.state);
                    // console.log("config", this._sceneConfig);
                    // console.log("config2", this._configurator.config);
                    this._assetProvider.getAssets().forEach((e) => {
                        if (e.infoType === "MATERIAL") {

                            // if (e.info.data.hasOwnProperty("metalness") && e.info.data.metalness > 1) {
                            //     console.log(e.info.name, e.info.data.metalness)
                            // }
                            materialSet.set(e.id, e.info.name);
                            materialNameMap.set(e.info.name, e);
                        } else if (e.infoType == "TEXTURE"){
                            textureNameMap.set(e.info.name, e);
                        }
                    });

                    this._buildVisibilitySetGeometryMap();

                    this.configurator.config.groupings.forEach(grp => {
                        if (!groupingMap.has(grp.geometry_id)) {
                            groupingMap.set(grp.geometry_id, []);
                        }
                        groupingMap.get(grp.geometry_id)?.push(grp)
                    });

                    this.configurator.config.default_state.grouping_material.forEach(grp => {
                        defaultMaterialMap.set(grp.id, grp.material_id);
                    });

                    this._configurator.config.material_groups.forEach(group => {
                        let mats = group.material_ids.map((e) => {
                            return { "id": e, "name": materialSet.get(e) }
                        })
                        let current = this._configurator.state.material_groups.find(e => e.id === group.id);
                        if (current) {
                            mats.forEach((m, i) => {
                                if (m.id === current.material_id) {
                                    let temp = mats[0];
                                    mats[0] = m;
                                    mats[i] = temp;
                                    return;
                                }
                            })
                        }
                        materialGroup.push({ "id": group.id, "name": group.name, "mats": mats })
                    });

                    backgrounds = this._assetProvider.getAssets().filter(a => a.infoType === AssetInfoTypes.Background).map(a => { return { name: a.info.name, id: a.id } });

                    /*this._configurator.config.environments.forEach((e)=>{
                        backgrounds.push({"id":e.id, "name":e.name});
                    });*/

                    //work around, waiting for Lorenz to fix
                    this.configurator._applyState(this.configurator.state)

                    visibilitySet = this._configurator.config.visibility_sets.map(e => { return { "id": e.id, "name": e.name, "group": e.visibility_group_ids } })

                    this._configurator.config.visibility_groups.forEach((e) => {
                        let visi = visibilitySet.find(e => e.name === "Fixture");
                        if (visi.group.includes(e.id)) {
                            modelSet.set(e.id, e.name);
                        }
                    });

                    this._configurator.config.visibility_groups.forEach((e) => {
                        let visi = visibilitySet.find(e => e.name === "Interior Scene");
                        if (visi.group.includes(e.id)) {
                            interiorSet.push({ id: e.id, name: e.name })
                        }
                    });

                    /*this._assetProvider.project.settings.renderSettings.collection.forEach((e)=>{
                        renderSettings.push({"id":e.id, "name":e.name});
                        console.log("render",  this._assetProvider.project.settings.renderSettings.collection);
                    })*/
                    //   console.log("material: ", materialGroup);
                    //   console.log("visi: ", visibilitySet);
                    //   console.log("model: ", modelSet);
                    //   console.log("CAM: ", this._engine.services.render.camera)
                    if (osName.toLowerCase().indexOf("os") >= 0) {
                        let cameras = this._assetProvider.getAssets().filter(e => e.infoType === AssetInfoTypes.Camera);
                        cameras.forEach(cam => {
                            cam.info.data.speed_zoom = 0.15;
                        })
                    }
                });
            })
    }


    private _buildVisibilitySetGeometryMap() {
        let assetMap = new Map<string, IAsset>();
        this._assetProvider.getAssets().forEach(asset => {
            assetMap.set(asset.id, asset);
        })
        let groupingGeometryMap= new Map<string, string>();
        this.configurator.config.groupings.forEach(grp => {
            groupingGeometryMap.set(grp.id, grp.geometry_id);
        });

        this._configurator.config.visibility_groups.forEach(vis_group => {
            if (vis_group.grouping_ids.length > 0) {
                let geometry_id= groupingGeometryMap.get(vis_group.grouping_ids[0]) as string;
                let asset= assetMap.get(geometry_id);
                visibiliySetGeometryMap.set(vis_group.name, asset?.files[0] as string);
            }
        });

        //console.log("vis_geo_map:", visibiliySetGeometryMap);
    }

    private _initPostEffects() {

        let settings = this._assetProvider.getAssets().filter(e => e.infoType === AssetInfoTypes.Settings);

        if (osName.toLowerCase().indexOf("os") >= 0) {
            //   console.log("IOS render")
            let setting = settings.find(setting => setting.info.name == "MAC");
            if (setting) {
                this._engine.services.render.settings.setSettings(setting.info.data)
            }
        }
        else {
            let setting = settings.find(setting => setting.info.name == "PC");
            if (setting) {
                //   console.log("PC render", setting)
                this._engine.services.render.settings.setSettings(setting.info.data)
            }
        }
        this._engine.services.render.settings.bloom.enabled = true;
    }

    public stopRender() {
        console.log("stop render 2");
    }

    public resize() {
        (this._engine as any)._onResize();
    }

    public changeRenderSetting(name: string) {
        let settings = this._assetProvider.getAssets().filter(e => e.infoType === AssetInfoTypes.Settings);
        let setting = settings.find(setting => setting.info.name == name);
        if (setting) {
            this._engine.services.render.settings.setSettings(setting.info.data)
        }
        else {
            if (osName.toLowerCase().indexOf("os") >= 0) {
                // console.log("IOS render")
                setting = settings.find(setting => setting.info.name == "MAC");
                if (setting) {
                    this._engine.services.render.settings.setSettings(setting.info.data)
                }
            }
            else {
                setting = settings.find(setting => setting.info.name == "PC");
                if (setting) {
                    // console.log("PC render", setting)
                    this._engine.services.render.settings.setSettings(setting.info.data)
                }
            }
        }
    }

    public getScreenShot(): Promise<any> {
        return new Promise((res, rej) => {
            this.frontCamera().then(() => {
                this._engine.services.render.screenshot({ size: { width: 2200, height: 1000 } }).then((img) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(img);
                    reader.onloadend = function () {
                        var base64data = reader.result;
                        res(base64data)
                    }
                })
            })
            /*const mount = document.getElementById( 'scene-canvas' ).getElementsByTagName( 'canvas' )[0];
            let base64data = this.trim(mount).toDataURL();
            res(base64data.substr(base64data.indexOf(',')+1))*/
        })
    }

    public frontCamera(): Promise<any> {
        this.resetCam();
        return new Promise((res, rej) => {
            setTimeout(() => { res() }, 200)
        })
    }

    public resetCam(name?: string, instant_transition?:boolean= false) {
        console.log('reset cam')
        //   console.log("camera", this._engine.services.render.camera)
        let ar: Object3D[] = [];
        this._engine.services.render.scene.traverse((obj) => {
            if (obj.type === "Mesh" && obj.visible) {
                ar.push(obj)
            }
        })
        let maxY = -100;
        let minY = -100;
        let maxX = -100;
        let minX = -100;
        let maxZ = -100;
        let minZ = -100;
        ar.forEach((o) => {
            const box = new Box3();
            box.setFromObject(o);
            if (box.max.y > maxY || maxY === -100) {
                maxY = box.max.y;
            }
            if (box.min.y < minY || minY === -100) {
                minY = box.min.y;
            }
            if (box.max.x > maxX || maxX === -100) {
                maxX = box.max.x;
            }
            if (box.min.x < minX || minX === -100) {
                minX = box.min.x;
            }
            if (box.max.z > maxZ || maxZ === -100) {
                maxZ = box.max.z;
            }
            if (box.min.z < minZ || minZ === -100) {
                minZ = box.min.z;
            }
            //const helper = new Box3Helper( box, 0xffff00 );
            //this._engine.services.render.scene.add(helper)
        })
        // console.log(`before set size maxY: ${maxY}, minY: ${minY}`);
        //set size
        if (maxY > 2) {
            console.log(maxY)
            maxY = maxY - 2.28;
            console.log(maxY)
        }
        if (minY < -2) {
            minY = minY + 2.28;
        }
        else if (minY < -1.8) {
            minY = minY + 1.2;
        }
        // console.log(minY)

        let size = maxY - minY;

        let sizeX = maxX - minX; //Math.sqrt((maxX-minX)*(maxX-minX) + (maxZ-minZ)*(maxZ-minZ))

        // console.log("size: " + size + " sizeX:" + sizeX);
        // console.log(`maxY: ${maxY}, minY: ${minY}`);

        let zoom = size / 0.4 * 1;

        if (sizeX > size) {
            zoom = sizeX / 0.4 * 1;
        }

        if (zoom < 1.1) {
            zoom = 1.1;
        }
        let camera = null;
        if (name) {
            camera = this._assetProvider.getAssets().filter(e => e.infoType === AssetInfoTypes.Camera).find(e => e.info.name === name);
        }
        if (!camera) {
            camera = this._assetProvider.getAsset(this.configurator.state.camera);
        }
        // console.log(this.configurator.state.camera)
        // console.log("cam cam",  camera)
        let cam = Object.assign(camera.info, {})
        if (cam.name === "Default") {
            cam.data.position = [-0.69 * zoom, 0.096 * zoom, 0.710 * zoom];
        }
        //cam.data.target = [0,-0.1,0];
        let duration= instant_transition? 0 : 200;
        this._engine.services.render.cameraController.setOrbitCamera(cam.data, { time: duration })
        // console.log("size", size)
    }

    public changeFixture(setID: string, groupID: string): Promise<any> {
        return new Promise((res, rej) => {
            let set = this.configurator.state.visibility_sets.find(e => e.id === setID);
            if (set && set.visibility_group_id === groupID) {
                return res();
            }
            this.configurator.setVisibilitySet(setID, groupID).then(() => {
                this.resetCam(modelSet.get(groupID));
                this.onFixtureChanged();
                res();
            }).catch(() => {
                res();
            })
        })

    }

    private degrees_to_radians(degrees:number) : number
    {
    // Store the value of pi.
    var pi = Math.PI;
    // Multiply degrees by pi divided by 180 to convert to radians.
    return degrees * (pi/180);
    }


    private _rotation:number= 0;
    public setRotation(rotation:number) {
        this._rotation= rotation;
        this.updateRotation();
    }

    private updateRotation() {
        var scene_fixtures = this._engine.services.render.scene.children[0].children;
        for (var i_f = 0; i_f < scene_fixtures.length; i_f++) {
            var fixture = scene_fixtures[i_f];
            var children = fixture.children;

            for (var i = 0; i < children.length; i++) {
                var child = children[i];

                if (child.name.startsWith("root_rotation")) {
                    child.rotation.y= this.degrees_to_radians(this._rotation);
                }

                for(var j= 0; j< child.children.length; j++) {
                    var child2= child.children[j];

                    if (child2.name.startsWith("root_rotation")) {
                        child2.rotation.y= this.degrees_to_radians(this._rotation);
                    }
                }
                //if (child.name.endsWith("_HTL") && child.morphTargetInfluences != null && child.morphTargetInfluences.length > 0) {
                //    child.morphTargetInfluences[0] = morph_t;
                //}
            }
        }
    }

    private onFixtureChanged() : void {
        this.updateRotation()
    }

    public changeFixtureMenu(setID: string, groupID: string) {
        this.configurator.setVisibilitySet(setID, groupID).then(() => {
            setTimeout(() => { this.resetCam() }, 100)
        })
    }

    public morph(name: string, value, notClamp?: boolean) {
        if (!name) {
            return;
        }

        var min_length = 1;
        var max_length = 100;
        const clamp = (a) => {
            return Math.min(1, Math.max(0, a));
        }

        const inverseLerp = (x, y, a) => {
            return ((a - x) / (y - x));
        }

        var hang_tube_length = value;
        if (notClamp) {

        }
        else {
            hang_tube_length = Math.max(min_length, Math.min(value, max_length))
        }

        var morph_t = inverseLerp(min_length, max_length, hang_tube_length);

        var scene_fixtures = this._engine.services.render.scene.children[0].children;
        for (var i_f = 0; i_f < scene_fixtures.length; i_f++) {
            var fixture = scene_fixtures[i_f];
            var children = fixture.children;

            for (var i = 0; i < children.length; i++) {
                var child = children[i];
                if (child.name.endsWith("_HTL") && child.morphTargetInfluences != null && child.morphTargetInfluences.length > 0) {
                    child.morphTargetInfluences[0] = morph_t;
                }
            }
        }

        var clips = this.getAnimationClips();
        var root = this._engine.services.render.scene.children[0];
        var mixer = new AnimationMixer(root);
        for (var clip of clips) {
            var action = mixer.clipAction(clip)
            action.clampWhenFinished = true;
            action.loop = LoopOnce;
            action.play();
        }

        mixer.setTime(morph_t * 4);
    }

    private getAnimationClips() {
        var animations = [];
        for (var entry of this._engine.services.geometry.animations) {
            animations.push(...entry.clips);
        }
        return animations;
    }

    public changeEnvironment(option) {
        this._configurator.setEnvironment(option)
    }

    public changeMaterial(groupID, option) {
        this._configurator.setMaterialGroup(groupID, option)
    }

    private forEachChildInTree(a:any[], func: (a : any) => void) {
        a.forEach(e => {
            func(e);
            this.forEachChildInTree(e.children, func);
        });
    }


    private light_temperature: number= 2700;
    public setLightTemperature(light_temperature: number) {
        this.light_temperature= light_temperature;
    }

    private intensity_mult_urlpara: number= 1;
    public setURLParaIntensityMult(intensity_mult_urlpara : number) {
        this.intensity_mult_urlpara= intensity_mult_urlpara;
    }

    public updateRoomMesh(isRoomEnabled:boolean, lamp_type:string) : Promise<void> {
        let id = "";
        let interior_option= "empty";
        if (isRoomEnabled) {
            switch (lamp_type) {
                case "wall_lamp":
                case "wall_lamp_high":
                    interior_option= "Interior Scene Wall Lamp";
                    break;
                case "table_lamp":
                    interior_option= "Interior Scene Table Lamp";
                    break;
                default:
                    interior_option= "Interior Scene";
            }
        }

        console.log("IO:", interior_option)

        interiorSet.forEach((v)=>{
            if(v.name === interior_option){
                id = v.id;
            }
        });

        return this.configurator.setVisibilitySet(visibilitySet.find(e => e.name === "Interior Scene").id, id);
    }

    private globeMaterialProfileMap = new Map<string, any>([
        ["CLEAR_GLASS",
            { profile_id: 1, intensity_mult: 1 }],
        ["MATTE_WHITE_GLASS",
            { profile_id: 4, intensity_mult: 1 }],
        ["ALABASTER",
            { profile_id: 4, intensity_mult: 1 }],
        ["FROSTED_GLASS",
            { profile_id: 3, intensity_mult: 1 }],
        ["SMOKED_GLASS",
            { profile_id: 1, intensity_mult: .4 }],
        ["VEINING_ALABASTER",
            { profile_id: 4, intensity_mult: 1 }],
        ["OPAL_GLASS",
            { profile_id: 4, intensity_mult: 1 }],
        ["CLEAR_TO_SMOKED_GRADIENT_GLASS",
            { profile_id: 1, intensity_mult: .5 }],
        ["FROSTED_TO_SMOKED_GRADIENT_GLASS",
            { profile_id: 3, intensity_mult: .5 }],
        ["SKYTEK_GLASS",
            { profile_id: 2, intensity_mult: 1 }],
        ["PIXEL_GLASS",
            { profile_id: 1, intensity_mult: 1 }],
        ["CUT_CRYSTAL",
            { profile_id: 1, intensity_mult: 1 }],
        ["REEDED_GLASS",
            { profile_id: 2, intensity_mult: 1 }],
        ["clear_to_frosted_gradient_glass",
            { profile_id: 3, intensity_mult: 1 }]
    ]);

    private lightDistanceMap= new Map<string, number>();

    // private prev_isLightEnabled:boolean= false;
    // private prev_model_name: string= "";
    // private prev_globe_material: string= "";
    // private prev_lamp_type: string= "";

    public updateLight(isLightEnabled: boolean, model_name: string, globe_material: string, lamp_type: string): void {
        console.log("engine: ", this);
        //console.log("globe_material:", globe_material);

        // this.prev_isLightEnabled= isLightEnabled;
        // this.prev_model_name= model_name;
        // this.prev_globe_material= globe_material;
        // this.prev_lamp_type= lamp_type;


        let room_visGroup : VisibilityGroup = {} as VisibilityGroup;
        let room_walllamp_visGroup : VisibilityGroup= {} as VisibilityGroup;
        let room_tablelamp_visGroup : VisibilityGroup= {} as VisibilityGroup;
        this._configurator.config.visibility_groups.forEach(vis_group => {
            if (vis_group.name == "Interior Scene") {
                room_visGroup= vis_group;
            } else if (vis_group.name == "Interior Scene Wall Lamp") {
                room_walllamp_visGroup= vis_group;
            } else if (vis_group.name == "Interior Scene Table Lamp") {
                room_tablelamp_visGroup= vis_group;
            }
        });

        let room_on_materials: IAsset[]= [];

        room_on_materials.push(materialNameMap.get("Room Ladder On") as IAsset);
        room_on_materials.push(materialNameMap.get("Room Walls On") as IAsset);
        room_on_materials.push(materialNameMap.get("Room Floor On") as IAsset);
        room_on_materials.push(materialNameMap.get("Room Ladder Wall Lamp On") as IAsset);
        room_on_materials.push(materialNameMap.get("Room Walls Wall Lamp On") as IAsset);
        room_on_materials.push(materialNameMap.get("Room Floor Wall Lamp On") as IAsset);
        room_on_materials.push(materialNameMap.get("Room Ladder Table Lamp On") as IAsset);
        room_on_materials.push(materialNameMap.get("Room Walls Table Lamp On") as IAsset);
        room_on_materials.push(materialNameMap.get("Room Floor Table Lamp On") as IAsset);


        let profile_number= 3;
        let light_intensity_mult= 1;
        let fixture_intensity_mult= 1;


        //console.log("---", model_name);
        if (this.metaData.fixture_data) {
            this.metaData.fixture_data.forEach(fixture => {
                if (fixture.name == model_name) {
                    

                    if (typeof fixture.default_profile !== 'undefined') {
                        profile_number= fixture.default_profile;
                    }

                    if (typeof fixture.intensity_mult !== 'undefined') {
                        fixture_intensity_mult= fixture.intensity_mult;
                    }

                    //console.log("!!!", model_name, fixture_intensity_mult);
                }
            });
        }


        if (this.globeMaterialProfileMap.has(globe_material)) {
            let profile= this.globeMaterialProfileMap.get(globe_material);
            profile_number= profile.profile_id;
            light_intensity_mult= profile.intensity_mult;
        } 
        //else if (model_name.includes("Alabaster")) {
        //     profile_number= 3;
        // } 
        let geometry_name= visibiliySetGeometryMap.get(model_name);

        if (geometry_name== undefined) {
             geometry_name= visibiliySetGeometryMap.get(model_name+"-SINGLE_GANG_J_BOX");
        } 

        if (geometry_name== undefined) {
            geometry_name= visibiliySetGeometryMap.get(model_name+"-SEMI_TIGHT");
        }

        let texture_name= geometry_name?.substring(0, geometry_name.length-4);
        let light_map_name= texture_name+"_L"+profile_number + ".png";
        let id_lightmap= "";
        if (isLightEnabled && textureNameMap.has(light_map_name)) {
            //console.log("ON:", light_map_name, textureNameMap.get(light_map_name));
            id_lightmap= textureNameMap.get(light_map_name)?.id as string;
        } else {
           // id_lightmap= "room_off.png";
        }

        if (id_lightmap != "") {
            room_on_materials.forEach(room_on_material => {
                room_on_material.assetList.forEach(mat_asset => {
                    if (mat_asset.name == "lightTex" || mat_asset.name === "emissiveTex" || mat_asset.name == "u_light_map_1") {
                        mat_asset.id= id_lightmap;
                    }
                });

                let base_intensity_legacy= 8;

                let base_intensity= 10;
                if (room_on_material.info.name.includes("Ladder")) {
                    base_intensity= 11; //12
                } else if (room_on_material.info.name.includes("Floor")) {
                    base_intensity= 11; //11
                }

                //console.log("int:", base_intensity * light_intensity_mult * fixture_intensity_mult);

                room_on_material.info.data.lightMapIntensity= base_intensity_legacy * light_intensity_mult * fixture_intensity_mult * this.intensity_mult_urlpara;

                room_on_material.info.data.properties.u_light_intensity= base_intensity * light_intensity_mult * fixture_intensity_mult * this.intensity_mult_urlpara;
                room_on_material.info.data.properties.u_color_temperature= this.light_temperature;

                //console.log("???", room_on_material.info.data.lightMapIntensity);
            });
        }

        let active_vis_group : VisibilityGroup;//= is_wall_lamp? room_walllamp_visGroup : room_visGroup;
        //console.log("is_wall_lamp", is_wall_lamp)

        switch (lamp_type) {
            case "wall_lamp": 
            case "wall_lamp_high": 
                active_vis_group= room_walllamp_visGroup;
                break;
            case "table_lamp":
                active_vis_group= room_tablelamp_visGroup;
                break;
            default:
                active_vis_group= room_visGroup;
        }

        active_vis_group.grouping_ids.forEach(grouping => {
            let default_mat_id= defaultMaterialMap.get(grouping) as string;

            if (isLightEnabled) {
                 let default_mat_name= materialSet.get(default_mat_id) + " On";
                default_mat_id= materialNameMap.get(default_mat_name)?.id as string;
                console.log(default_mat_name, default_mat_id, grouping)
            }

            this.configurator.setMaterial(grouping, default_mat_id);

            this._engine.services.material.updateMaterial(default_mat_id);
    
        });


        this.services.render.scene.children[0].children.forEach(rootMesh => {
            var geo_id_ = rootMesh.name;

            //console.log(groupingMap.get(geo_id_));
            var groupings: Grouping[] = groupingMap.get(geo_id_) as Grouping[];

            this.forEachChildInTree(rootMesh.children, child => {
                var is_glass: boolean = child.userData.hasOwnProperty("vc_is_glass") && child.userData["vc_is_glass"];
                var is_bulb: boolean = child.userData.hasOwnProperty("vc_is_bulb") && child.userData["vc_is_bulb"];

                if (child.type== "PointLight") {
                    var light_uid= geo_id_+"___"+child.name;
                    if (!this.lightDistanceMap.has(light_uid)) {
                        this.lightDistanceMap.set(light_uid, child.distance* 1.5);
                    }

                    child.distance= this.lightDistanceMap.get(light_uid);
                    //console.log("LIGHT: ", child);

                    child.visible= isLightEnabled;
                    child.decay= 2;
                    child.intensity= .5 * (light_intensity_mult * fixture_intensity_mult+0.5)/1.5;
                    child.color.r= 1;
                    child.color.g= 0.467;
                    child.color.b= 0.176;
                }

                var meshGrouping: Grouping= {} as Grouping;
                var groupingFound: boolean= false;
                groupings.forEach(grouping => {
                    if (grouping.object_names.indexOf(child.name) >= 0) {
                        meshGrouping = grouping;
                        groupingFound= true;
                    }
                });

                if (groupingFound) {
                    var mat_id= defaultMaterialMap.get(meshGrouping.id) as string;
                    var mat_name= materialSet.get(mat_id);

                    //only apply lit material here if the group's standard material isn't SILOUETTE_01 (in which case the material gets controlled by a material group)
                    if (mat_name != "SILHOUETTE_01" && mat_name != "Rubber Black" && mat_name!= "Bronze Dark" && mat_name != "Steel Dark") {
                        var lit_name= mat_name;
                        if (is_glass) {
                            lit_name= mat_name+"_LIT";
                        }
                        if (is_bulb) {
                            lit_name= "Bulb_LIT";
                        }

                        if (isLightEnabled && materialNameMap.has(lit_name)) {
                            mat_id= materialNameMap.get(lit_name)?.id as string;

                        }

                        if (is_glass || is_bulb) {
                            this.configurator.setMaterial(meshGrouping.id, mat_id);
                        }
                    }
                }


            });
        })
    }
}