import * as React from "react";
import './Toolbar.css';
import { MainController } from "../../service/MainController";
import ReactSlider from 'react-slider';

interface Props{
    toolbarHidden:boolean;
    controller:MainController;
    rotationSliderHidden:boolean
}

interface State{
    toolbarText:string,
    modal:string
}

export class Toolbar extends React.Component<Props,State>{
    constructor(props:Props){
        super(props);
        this.state={
            toolbarText:'',
            modal:''
        }
    }

    componentDidMount(): void {
        MainController.event.on("modalChange", (e)=>{
            this.setState({modal: e.modal})
        })
    }
    
    private hoverHandle(text:string){
        this.setState({toolbarText:text})
    }

    private mouseOutHandle(){
        this.setState({toolbarText:''})
    }

    private openStorePage() {
        this.props.controller.onOpenStorePage();
    }

    private hoverHandleModel()  {
        let text:string = this.state.modal;
        text= text.replace("-4_HEX_J_BOX", "");
        text= text.replace("-SINGLE_GANG_J_BOX", "");
        text= text.replace("-SEMI_TIGHT", "");
        text= text.replace("-BLACK_WHITE", "");
        text= text.replace("-RINGS", "");
        text= text.replace("-FIREFLY", "");

        this.setState({toolbarText:text})
    }

    private getShowLogo() : boolean {
        try {
            return window.self == window.top;
        } catch (e) {
            return false;
        }
    }


    private tooglefullScreen(){
        if(window.parent){
            if(MainController.fullscreen){
                window.parent.postMessage('exit-fullscreen', "https://5179281-sb1.app.netsuite.com/")
            }
            else{
                window.parent.postMessage('fullscreen', "https://5179281-sb1.app.netsuite.com/")
            }
        }
    }

    private onRotationSliderValueChange(value:number, thumb_index:number) {
        this.props.controller.onSetRotation(value);
    }

    render(){
        return (this.props.toolbarHidden) ? null : <div className="toolbar">
            {this.getShowLogo() && <div className="logo-wrapper">
                <img src="https://srv.3dcommerce.studio/public/files?project=61b1e2380982360016470139&file=Logo-23-Web-White-p-500.png" alt="logo" />
            </div>}
            <div className="toolbar-wrapper">
                {this.state.toolbarText && <div className="toolbar-title">{this.state.toolbarText}</div>}      
                <ToolbarItem 
                    imgURL="https://srv.3dcommerce.studio/public/files?project=61b1e2380982360016470139&file=image_fa.png"              
                    onClick={()=>{this.props.controller.onToggleEnvironment()}}        
                    onHover={()=>this.hoverHandle('Environment')}
                    onMouseOut={()=>this.mouseOutHandle()}
                    isToggle={true}
                />          
                <ToolbarItem 
                    imgURL="https://srv.3dcommerce.studio/public/files?project=61b1e2380982360016470139&file=lightbulb-on_fa.png"  
                    onClick={()=>{this.props.controller.onToggleLight()}}                    
                    onHover={()=>this.hoverHandle('Lights')}
                    onMouseOut={()=>this.mouseOutHandle()}
                    isToggle={true}
                />
                <ToolbarItem 
                    imgURL="https://srv.3dcommerce.studio/public/files?project=61b1e2380982360016470139&file=link_fa.png"    
                    onClick={()=>{
                        navigator.clipboard.writeText(window.location.href);
                        // Alert the copied text
                        alert("Link copied");
                    }}                   
                    onHover={()=>this.hoverHandle('Copy url')}
                    onMouseOut={()=>this.mouseOutHandle()}
                    isToggle={false}
                />
                <ToolbarItem 
                    imgURL="https://srv.3dcommerce.studio/public/files?project=61b1e2380982360016470139&file=circle-info_fa.png"                    
                    onHover={()=>this.hoverHandleModel()}
                    onClick={()=>{this.openStorePage()}}
                    onMouseOut={()=>this.mouseOutHandle()}
                    isToggle={false}
                />
                <ToolbarItem 
                    imgURL="https://srv.3dcommerce.studio/public/files?project=61b1e2380982360016470139&file=arrows-maximize_fa.png" 
                    onClick={()=>{this.tooglefullScreen()}}
                    onHover={()=>this.hoverHandle('full screen')}
                    onMouseOut={()=>this.mouseOutHandle()}
                    isToggle={true}
                />
                {!this.props.rotationSliderHidden && (
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    min={-180}
                    max={180}
                    onChange= {(val, index)=> this.onRotationSliderValueChange(val, index)}
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    with
                /> )}           
            </div>
        </div>
    }
}

interface IProps{
    imgURL:string,
    onClick?:()=>void,
    onHover:()=>void,
    onMouseOut:()=>void,
    isToggle:boolean
}

interface IState{
    active:boolean
}

export class ToolbarItem extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            active:false
        };
    }

    render(){
        return <div 
            className={`toolbar-item${this.state.active ? ' active' : ''}`} 
            onClick={()=>{
                if (this.props.isToggle) {
                    this.setState({active:!this.state.active});
                }
                if(this.props.onClick){
                    this.props.onClick();
                }
            }} 
            onMouseOver={this.props.onHover}
            onMouseOut={this.props.onMouseOut}                   
        >
            <img src={this.props.imgURL} alt="" />
        </div>
    }
}